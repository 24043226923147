export const updateAcSeriesReducer = (state: mpdDrawerState, action: mpdDrawerAction) => ({
  ...state,
  AircraftSeriesType: action.payload as FilterEffectivityType[],
});

export const updateMsnRnoReducer = (state: mpdDrawerState, action: mpdDrawerAction) => ({
  ...state,
  AircraftMsnRnoType: action.payload as FilterEffectivityType[],
});

export const updateMsnRnoSearchReducer = (state: mpdDrawerState) => ({
  ...state,
  AircraftMsnRnoSearchType: state.AircraftMsnRnoType as FilterEffectivityType[],
});

export const updateModuleNameTask = (state: mpdDrawerState, action: updateModuleNameAction) => ({
  ...state,
  ModuleName: action.payload as ModuleNameType,
});

// Async reducers
export const createMpdFilterPending = (state: mpdDrawerState) => ({
  ...state,
  loading: true,
});

export const createMpdFilterFulfilled = (state: mpdDrawerState, action: CreateMpdFilterFulfilledAction) => ({
  ...state,
  loading: false,
  AircraftFilters: [...action.payload.msn_reg.map((val) => Object.values(val)[0])] as FilterEffectivityType[],
  AircraftSeriesType: [...action.payload.msn_reg.map((val) => Object.values(val)[0])] as FilterEffectivityType[],
  MsnAndRegFilters: action.payload.msn_reg,
  AircraftMsnRnoType: [...action.payload.msn_reg.map((val) => Object.values(val)[1]).flat()] as FilterEffectivityType[],
  error: '',
});

export const createMpdFilterRejected = (state: mpdDrawerState, action: CreateMpdFilterRejectedAction) => ({
  ...state,
  loading: false,
  AircraftSeriesType: [],
  error: action.payload || action.error?.message,
});

export const updateFilterSubmitStateReducer = (state: mpdDrawerState, action: submitFilterStateAction) => ({
  ...state,
  filterSubmitState: action.payload,
});
