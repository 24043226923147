/* eslint-disable @typescript-eslint/no-explicit-any */
import { Methods } from '../../utils/customHooks/apiTypes';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  CREATE_REPOSITORY_FILTER, DELETE_BUILD, FETCH_REPOSITORY_DATA, RETRY_BUILD, UPDATE_TITLE, PRECOMPUTE_API_PATH, AC_TYPE_MAINTENANCE_PROGRAM_MAPPING,
} from './constants';

export const architecture = () => secureLS.get('architecture');

export const fetchPARepositoryData: any = (operator: string, acProgram: string) => {
  const customAction = {
    sliceName: FETCH_REPOSITORY_DATA,
    actionType: CREATE_REPOSITORY_FILTER,
  };
  const apiRequestObj = {
    path: `${PRECOMPUTE_API_PATH}?ac_program=${AC_TYPE_MAINTENANCE_PROGRAM_MAPPING[acProgram]}`,
    method: 'get' as Methods,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const deleteBuildData: any = (id: string) => {
  const customAction = {
    sliceName: DELETE_BUILD,
    actionType: CREATE_REPOSITORY_FILTER,
  };
  const apiRequestObj = {
    path: `${PRECOMPUTE_API_PATH}/${id}`,
    method: 'del' as Methods,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const retryBuildData: any = (id: string) => {
  const customAction = {
    sliceName: RETRY_BUILD,
    actionType: CREATE_REPOSITORY_FILTER,
  };
  const apiRequestObj = {
    path: `${PRECOMPUTE_API_PATH}/${id}`,
    method: 'post' as Methods,
    body: { rebuild: 'true' },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const updateTitle: any = (id: string, newTitle: string) => {
  const customAction = {
    sliceName: UPDATE_TITLE,
    actionType: CREATE_REPOSITORY_FILTER,
  };
  const apiRequestObj = {
    path: `${PRECOMPUTE_API_PATH}/${id}`,
    method: 'post' as Methods,
    body: {
      title: newTitle,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
