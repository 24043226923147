// Slice
export const MPD_EMTL_SLICE = 'emtlTable';

// Async thunk actions
export const FETCH_EMTL_FILTER_VALUE = 'fetchEmtlFilterValue';
export const FETCH_TADA_EMTL_FILTER_VALUE = 'fetchTadaEmtlFilterValue';
export const CREATE_EMTL_FILTER = 'createEmtlTableFilter';
export const CREATE_TADA_EMTL_FILTER = 'createTadaEmtlTableFilter';

export const FETCH_MPD_EMTL_TABLE = 'fetchEmtlTable';
export const CREATE_MPD_EMTL_TABLE = 'createMpdEmtlTable';

export const FETCH_MPD_TABLE = 'fetchMpdTable';
export const CREATE_MPD_TABLE = 'createMpdTable';

export const FETCH_MPD_EMTL_TABLE_EXPORT = 'fetchEmtlTableExport';
export const CREATE_MPD_EMTL_TABLE_EXPORT = 'createMpdEmtlTableExport';
export const CREATE_MPD_TABLE_EXPORT_TASK = 'createMpdTableExportTask';
export const GET_FILE_URL = 'getFileUrl';

// API Path
export const FILTER_API_PATH = 'api/csm/emtlTableFilters';
export const FILTER_TADA_API_PATH = 'api/csm/mpdfilter';
export const EMTL_TABLE_API_PATH = 'api/csm/emtltable';
export const MPD_TABLE_API_PATH = 'api/csm/mpdtable';
export const EMTL_TABLE_EXPORT_API_PATH = 'api/csm/export-emtl-table';
export const EMTL_TABLE_EXPORT_TASK_API_PATH = 'api/csm/exporttask';
