import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row, Card, Col, Button, Tab, Tabs, Chip, Inline, CardContent,
} from '@airbus/components-react';
import './MpdRepositoryEmtlDeltaGeneralInfoCard.scss';
import { RootState } from '../../store/store';
import { useAppSelector } from '../../store/hooksTypes';
import MpdRepositoryCompareFilterDialog from '../MpdRepositoryCompareFilterDialog/MpdRepositoryCompareFilterDialog';

/* eslint-disable @typescript-eslint/no-explicit-any */

/*
We only export these in order to test them and increase coverage. It is pretty useless to test
functions like these.
*/

interface emtlCardSubProps {
  title: string;
  value: string;
}

const subDataFunction = function EffectiveMaintenanceTaskList(effectiveMaintenenceTaskListDataArray: emtlCardSubProps[], tab: boolean, cssClass: string, cssTitleClass: string) {
  return (
    effectiveMaintenenceTaskListDataArray && effectiveMaintenenceTaskListDataArray.map((subVal: emtlCardSubProps) => (
      <span className={cssTitleClass} key={subVal.title}>
        <span className={cssClass}>
          {subVal.title}
          :
          {tab && <br />}
          <span>
            <b>{` ${subVal.value}`}</b>
          </span>
        </span>
      </span>
    ))
  );
};

const renderChipFunction = function RenderChip(activetab: number, tab: boolean, issueData: emtlCardSubProps[][], establishedData: emtlCardSubProps[][]) {
  return (
    <Row className="emtl-chart-container-content-row-spacing-delta emtl-chart-custom-container-spacing">
      <Col xxs={6}>
        <Inline spacing="8-x">
          {subDataFunction(issueData[activetab], tab, 'emtl-chart-container-content-col1-issue-data', '')}
        </Inline>
      </Col>
      <Col xxs={2} className="estFromHeader">
        <b>Established From</b>
      </Col>
      <Col xxs={4}>
        <div id="estFromDelta">
          {subDataFunction(establishedData[activetab], tab, 'emtl-chart-container-content-col-established-from', 'estFromTitle')}
        </div>
      </Col>
    </Row>
  );
};

interface MpdRepositoryEmtlDeltaGeneralInfoCardProps {
  data: generalInfoCardData;
  id: string | undefined;
}

const MpdRepositoryEmtlDeltaGeneralInfoCard = function EffectiveMaintenanceTaskList(props: MpdRepositoryEmtlDeltaGeneralInfoCardProps) {
  const { data, id } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const {
    RepositoryFilterPanalOpen: tab, RepositoryDeltaViewCardIssueData: issueData, RepositoryDeltaViewCardEstablishedData: establishedData,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  return (
    <Card className="smpc-chart-container-content-col1-delta smpc-chart-custom-container">
      <CardContent>
        <Row className="emtl-chart-container-content-col1-delta emtl-chart-custom-container">
          <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Tabs
              aria-label="EmtlDeltaTab"
              value={selectedTab}
              onChange={(event, value) => setSelectedTab(value)}
            >
              <Tab
                aria-label="ReferenceFileTab"
                style={{ paddingBottom: '1rem' }}
              >
                {data.reference_file}
              </Tab>
              <Tab
                aria-label="SourceFileTab"
                style={{ paddingBottom: '1rem' }}
              >
                {data.input_file}
              </Tab>
            </Tabs>
          </Col>
          <Col xxs={6}>
            <Inline align="end" alignY="start" spacing="2-x">
              {selectedTab === 0 && <Chip className="emtl-delta-reference-file-chip" aria-label="ReferenceFileChip" label="Reference File" size="small" variant="success" style={{ color: '#282e3a', fontWeight: 'bold' }} clickable />}
              <MpdRepositoryCompareFilterDialog open={open} onClose={handleClose} headers={data.ac_msn_diff.headers} data={data.ac_msn_diff.data} />
              <Button variant="primary" size="medium" onClick={handleOpen} disabled={!data.ac_msn_diff.headers.length}>Compare Filter</Button>
              <Button variant="primary" size="medium" onClick={() => navigate(`../emtl/view/soc/${id}`)}>
                Summary of Changes
              </Button>
            </Inline>
          </Col>
        </Row>
        {renderChipFunction(selectedTab, tab, issueData, establishedData)}
      </CardContent>
    </Card>
  );
};

export default MpdRepositoryEmtlDeltaGeneralInfoCard;
