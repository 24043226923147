/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FETCH_BUILD, FETCH_BUILD_METADATA,
  FETCH_FILTERS, GET_BUILD_METADATA_ENDPOINT,
  GET_FILTERS_ENDPOINT,
  GET_FILTERS_PRECOMP_ENDPOINT, POST_FILTERS_PRECOMP_ENDPOINT,
  GET_TASKS_ENDPOINT, MAINTENANCE_PROGRAM, OPERATOR_ICAO_CODE,
  POST_FILTERS,
  POST_FILTERS_ENDPOINT,
  PROGRAM_ANALYSIS_SLICE, TIME_FRAME_YEAR, GET_BUILD_METADATA_ENDPOINT_PRECOMP, GET_TASKS_PRECOMP_ENDPOINT,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';
import { fetchFiltersObject, filtersValues } from './programAnalysisTypes';
import { APIRequestObj } from '../../utils/thunkUtils/thunkTypes';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';

const getValuesFromLocalStorage = () => ({
  [OPERATOR_ICAO_CODE]: secureLS.get('operator'),
  [MAINTENANCE_PROGRAM]: secureLS.get('ac_program'),
});

export const getPAFilters: any = (payload: fetchFiltersObject) => {
  const customAction = {
    sliceName: PROGRAM_ANALYSIS_SLICE,
    actionType: FETCH_FILTERS,
  };

  let apiRequestObj: APIRequestObj = {
    path: GET_FILTERS_PRECOMP_ENDPOINT,
    method: 'post' as Methods,
  };
  if (payload) {
    const { [TIME_FRAME_YEAR]: _, ...cleanPayload } = payload;
    apiRequestObj = { ...apiRequestObj, body: { ...cleanPayload, ...getValuesFromLocalStorage() } };
  }

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const postPAFilters: any = (payload: Partial<filtersValues>) => {
  const customAction = {
    sliceName: PROGRAM_ANALYSIS_SLICE,
    actionType: POST_FILTERS,
  };

  // eslint-disable-next-line camelcase
  const { [MAINTENANCE_PROGRAM]: ac_program, ...cleanStorage } = getValuesFromLocalStorage();
  let body;
  if (payload) {
    const { [MAINTENANCE_PROGRAM]: _program, ...cleanPayload } = payload;
    body = {
      // eslint-disable-next-line camelcase
      ...cleanPayload, ...(payload && typeof payload[TIME_FRAME_YEAR] === 'string' ? { [TIME_FRAME_YEAR]: parseInt(payload[TIME_FRAME_YEAR].substring(0, payload[TIME_FRAME_YEAR].length - 1), 10) } : null), ...cleanStorage, ac_program,
    };
  } else {
    // eslint-disable-next-line camelcase
    body = { ...cleanStorage, ac_program };
  }
  const apiRequestObj = {
    path: POST_FILTERS_PRECOMP_ENDPOINT,
    method: 'post' as Methods,
    body,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const getBuild: any = (buildId: string) => {
  const customAction = {
    sliceName: PROGRAM_ANALYSIS_SLICE,
    actionType: FETCH_BUILD,
  };
  const apiRequestObj = {
    path: GET_TASKS_PRECOMP_ENDPOINT(buildId),
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const getBuildMetadata: any = (buildId: string) => {
  const customAction = {
    sliceName: PROGRAM_ANALYSIS_SLICE,
    actionType: FETCH_BUILD_METADATA,
  };

  const apiRequestObj = {
    path: GET_BUILD_METADATA_ENDPOINT_PRECOMP(buildId),
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
