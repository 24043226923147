import React, { useEffect } from 'react';
import {
  Row, Card, Col,
} from '@airbus/components-react';
import './MpdGeneralInfoCard.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { updateEmtlExportButtonState } from '../../models/mpdEmtlTableModel/mpdEmtlTableSlice';
/* eslint-disable @typescript-eslint/no-explicit-any */

/*
We only export these in order to test them and increase coverage. It is pretty useless to test
functions like these.
*/

interface mpdGeneralCardSubProps {
  title: string;
  value: string;
}

const subDataFunction = function subDataFunction(mpdGeneralInfoCardDataArray: mpdGeneralCardSubProps[], cssClass: string, id?: string) {
  return (
    <div id={id}>
      {mpdGeneralInfoCardDataArray && mpdGeneralInfoCardDataArray.map((subVal: mpdGeneralCardSubProps) => (
        <span className="smpc-chart-container-content-col1" key={subVal.title}>
          <span className={cssClass}>
            {subVal.title}
            :
            <span className="bold-title">
              {'\u00a0'}
              {subVal.value}
            </span>
          </span>
        </span>
      ))}
    </div>
  );
};

const MpdGeneralInfoCard = function MpdGeneralInfoCard() {
  const dispatch = useAppDispatch();
  const {
    MpdGeneralInfoIssueData: issueData, MpdGeneralInfoEstablishedFrom: establishedFromData,
  } = useAppSelector((state: RootState) => state.mpdGeneralInfo);

  const {
    EmtlTableDataRowFilteredDataType: emtlTableDataRowFiltered,
  } = useAppSelector((state: RootState) => state.emtlTable);

  useEffect(() => {
    if (issueData[0].value !== '' && emtlTableDataRowFiltered.length) dispatch(updateEmtlExportButtonState(false));
    else dispatch(updateEmtlExportButtonState(true));
  }, [issueData, emtlTableDataRowFiltered, dispatch]);

  return (
    <Card className="smpc-chart-container">
      <Row className="emtl-chart-container-content-row-spacing">
        <Col xxs={5}>
          {subDataFunction(issueData, 'emtl-chart-container-content-col1')}
        </Col>
        <Col xxs={2}>
          <span className="bold-title title-right">Established from</span>
        </Col>
        <Col xxs={5}>
          {subDataFunction(establishedFromData, 'emtl-chart-container-content-col1-established-from', 'estFrom')}
        </Col>
      </Row>
    </Card>
  );
};

export default MpdGeneralInfoCard;
