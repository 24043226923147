/**
 * A function to download base64 encoded Excel file from API
 * This way of downloading excel file to be removed once files are moved to S3 storage
 *
 * @param excel Excel file content as a base64 encoded string
 * @param emtlEstablishedFrom State of emtlEstablishedFrom to extract mpdRevision
 * @param operator Operator name as string
 * @param acProgram A/C Program as string
 */
const downloadExcel = (excel: string, emtlEstablishedFrom: MpdGeneralInfoSubdataType[], operator: string, acProgram: string | undefined) => {
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excel}`;
  const downloadLink = document.createElement('a');
  let acProgramName = acProgram;
  let mpdRevision = emtlEstablishedFrom[0].value;
  // This fix will be removed once files are moved to S3 storage as filename info will come from API
  if (acProgramName?.includes('A320')) {
    acProgramName = acProgramName.replace('A320', 'A320FAM');
  }
  if (acProgramName?.includes('DELTA')) {
    mpdRevision = '';
  }
  downloadLink.href = linkSource;
  downloadLink.setAttribute('download', `${operator}_${acProgramName}${mpdRevision}.xlsx`);
  downloadLink.click();
};

/**
 * A function to extract issueNumber, issueDate, mpdRevision and revisionDate from
 * emtlEstablishedFrom and emtlIssueData redux states
 *
 * @param emtlEstablishedFrom Redux state of emtlEstablishedFrom to extract mpdRevision, revisionDate
 * @param emtlIssueData Redux state of emtlIssueData to extract issueNumber, issueDate
 */
const extractGeneralInfoData: (emtlEstablishedFrom: MpdGeneralInfoSubdataType[], emtlIssueData: MpdGeneralInfoSubdataType[]) => MpdGeneralInfoExtracted = (emtlEstablishedFrom, emtlIssueData) => {
  let [issueNumber, issueDate, mpdRevision, mpdIssueNumber, revisionDate, mpdIssueDate] = ['', '', '', '', '', ''];
  if (emtlIssueData.length >= 2) {
    issueNumber = emtlIssueData[0].value;
    issueDate = emtlIssueData[1].value;
  }
  if (emtlEstablishedFrom.length >= 2) {
    mpdRevision = emtlEstablishedFrom[0].value;
    mpdIssueNumber = emtlEstablishedFrom[1].value;
    revisionDate = emtlEstablishedFrom[2].value;
    mpdIssueDate = emtlEstablishedFrom[3].value;
  }
  return {
    issueNumber, issueDate, mpdRevision, mpdIssueNumber, revisionDate, mpdIssueDate,
  };
};

const getFilteredItems = (listOfItems: string[], itemName: string) => {
  const selectedItem = listOfItems.filter((item) => {
    return item !== itemName;
  });
  return selectedItem;
};

export {
  downloadExcel, extractGeneralInfoData, getFilteredItems,
};

export type fileURLReturnType = {
  data: {
    downloadUrl: string,
    taskId: string
  }
}
