import React, { useEffect, useRef, useState } from 'react';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import {
  Container,
} from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { getBuild, getBuildMetadata, getPAFilters } from '../../models/programAnalysis/programAnalysisAsyncThunks';
import FilterDrawer from '../FilterDrawer/FilterDrawer';
import EmptyContent from '../Shared/EmptyContent/EmptyContent';
import PAOverview from './Overview/PAOverview';
import { maintenanceTask } from '../../models/programAnalysis/programAnalysisTypes';
import TaskList from './TaskList/TaskList';
import { updateBuildInfoObject } from '../../models/programAnalysis/programAnalysisSlice';

import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { RootState } from '../../store/store';
import { clearPABuildList } from '../../models/PABuildList/PABuildListSlice';

import { allFiltersKeys } from '../FilterDrawer/constants';
import {
  BUILD_ID, BUILD_STATUS, IMPACTED_BY_ANNEX12, MAINTENANCE_PROGRAM, OPERATOR_ICAO_CODE, POSSIBLE_BUILD_STATUS,
} from '../../models/programAnalysis/constants';
import './PAnalysis.scss';

/* eslint-disable react-hooks/exhaustive-deps */
const PAnalysis: React.FC = () => {
  const pollDurationInSeconds = 10;
  const maxPollAttempts = 30;
  const pollAttemptsRef = useRef(0);
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const timerIdRef = useRef<any>(null);

  const { performance } = useAppSelector((state: RootState) => state.programAnalysis);
  const {
    build: buildInfo, tasks: taskObj,
  } = useAppSelector((state: RootState) => state.programAnalysis);

  const urlParams = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const subtitles = ['Calculating operator sample finding rate...',
    'Calculating world wide finding rate...',
    'Calculating operator yield...',
    'Calculating fleet yield...',
    'Calculating cost..'];

  const getRandomInt = (max : number) => {
    const d = new Date();
    return (d.getSeconds() % max);
  };

  /*
    Simple function to control what is displayed on the screen.
   */
  const switchMainScreen = (): JSX.Element | undefined => {
    if (buildInfo.fetchInfo.loading && buildInfo[BUILD_STATUS] === '') {
      return (
        <EmptyContent
          title="Checking Build Status"
          subtitle=""
          status={POSSIBLE_BUILD_STATUS.INIT}
        />
      );
    }
    // if the filter is impacted by annex 13 then block the user to see the results
    if (buildInfo[IMPACTED_BY_ANNEX12]) {
      return (
        <EmptyContent
          title="Build Impacted by Annnex-13"
          subtitle="You can access your saved results in repository"
          status="IMPACTED"
        />
      );
    }

    switch (buildInfo[BUILD_STATUS]) {
      case POSSIBLE_BUILD_STATUS.READY:
        return (
          <EmptyContent
            title="Loading Computation..."
            subtitle=""
            status={POSSIBLE_BUILD_STATUS.INIT}
          />
        );
      case POSSIBLE_BUILD_STATUS.INIT:
      case POSSIBLE_BUILD_STATUS.IN_PROGRESS:
        return (
          <EmptyContent
            title="Build In Progress"
            subtitle={subtitles[getRandomInt(5)]}
            status={POSSIBLE_BUILD_STATUS.IN_PROGRESS}
            progress={[buildInfo.progress_percentage || 0, 100]}
          />
        );
      case POSSIBLE_BUILD_STATUS.FAILED:
        return <EmptyContent title="Last build failed" subtitle="An error occurred with the latest build of data, please rebuild the filter" status={POSSIBLE_BUILD_STATUS.FAILED} />;
      case POSSIBLE_BUILD_STATUS.UNAUTHORIZED:
        return <EmptyContent title="Analysis No Longer Exists" subtitle="The analysis you requested has been deleted or doesn't exist" status={POSSIBLE_BUILD_STATUS.UNAUTHORIZED} />;
      case '':
        return <EmptyContent title="Access saved results" subtitle="You can access your saved results in repository" status="EMPTY" />;
      default:
        return <EmptyContent title="Build status not handled" subtitle="An error occurred, please contact the appropriate person" status="UNKNOWN" />;
    }
  };

  /*
    Simple function deciding if we display the Overview component or not. Was too long to put in the code directly.
    We check if :
    - We have data (array, and the array length > 0
    - The build ID in the URL corresponds to the one in the Redux store
   */
  const displayOverview = () => {
    return ((taskObj.data as maintenanceTask[]) && (taskObj.data as maintenanceTask[]).length) > 0 && !taskObj.fetchInfo.loading && buildInfo[BUILD_STATUS] === POSSIBLE_BUILD_STATUS.READY;
  };

  const clearPABuildListCleanup = () => {
    dispatch(clearPABuildList());
  };

  const stopPolling = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = null;
  };

  // this function is to avoid caching issue of GETBUILD api in task distribution page
  const handleBuildInfoConditions = () => {
    // If the build status is READY and there is no previous data
    if (buildInfo[BUILD_STATUS] === POSSIBLE_BUILD_STATUS.READY && !buildInfo[IMPACTED_BY_ANNEX12]) {
      return true;
    }
    return false;
  };

  /*
    Loading data when mounting the component
    We change the ID of the build in Redux store, which will in turn trigger the below useEffect
    We also get the filters so that we can fill them when retrieve the build metadata below
    If data is already there, we don't re-fire request as we already have all we need
   */
  useEffect(() => {
    // dispatch(clearPARepoState());
    if (displayOverview()) return clearPABuildListCleanup;
    dispatch(updateBuildInfoObject({ id: urlParams.id }));
    if (urlParams.id && urlParams.id !== '') {
      dispatch(getPAFilters({ [OPERATOR_ICAO_CODE]: secureLS.get('operator'), [MAINTENANCE_PROGRAM]: secureLS.get('ac_program'), select: allFiltersKeys })({ initial: true }));
    }
    return () => {
      clearPABuildListCleanup();
      stopPolling();
    };
  }, []);

  /*
    We get the build metadata if the ID exist and is not empty
    As per the above useEffect, we don't fire the request if the metadata is already there
   */
  useEffect(() => {
    if (displayOverview()) return;
    if (buildInfo[BUILD_ID] && buildInfo[BUILD_ID] !== '') {
      dispatch(getBuildMetadata(buildInfo[BUILD_ID])());
      if (!urlParams.id) {
        navigate(`${buildInfo[BUILD_ID]}`);
      } else {
        navigate(`../${buildInfo[BUILD_ID]}`, { replace: true });
      }
    }
  }, [buildInfo[BUILD_ID]]);

  /*
    We navigate to the right URL when the ID is updated, even when it is not READY.
    This way, the user can easily refresh the page without re-selecting the filters over and over
    If the build status is READY and there is no previous data, we fetch the data and clear the previous selected task if any
   */
  useEffect(() => {
    // const { filterId: prevFilterId } = performance;
    if (buildInfo[BUILD_ID] && buildInfo[BUILD_ID] !== '') {
      // handle BuildInfoCondition function return TRUE If the build status is READY and there is no previous data,
      // in NewArchitecture we are checking if filter is not same as previous filter then we hit Api to avoid Caching
      if ((buildInfo[BUILD_ID] !== performance.prevBuildId && handleBuildInfoConditions())) {
        dispatch(getBuild(buildInfo[BUILD_ID])());
      }
    }
  }, [buildInfo[BUILD_STATUS], buildInfo[BUILD_ID], urlParams.id]);

  // precomp changes
  useEffect(() => {
    const pollingCallback = () => {
      // call build status api
      dispatch(getBuildMetadata(buildInfo[BUILD_ID])()).unwrap();

      if (buildInfo[BUILD_STATUS] === POSSIBLE_BUILD_STATUS.READY
        || pollAttemptsRef.current > maxPollAttempts) {
        setIsPollingEnabled(false);
      }
      pollAttemptsRef.current += 1;
    };

    const startPolling = () => {
      // Polling every 15 seconds
      timerIdRef.current = setInterval(pollingCallback, pollDurationInSeconds * 1000);
    };

    if ([POSSIBLE_BUILD_STATUS.INIT, POSSIBLE_BUILD_STATUS.IN_PROGRESS].includes(buildInfo[BUILD_STATUS] as string)
      && isPollingEnabled
    ) {
      // Dont start polling if an existing polling is already in progress
      if (!timerIdRef.current) {
        startPolling();
      }
    } else {
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildInfo.status, buildInfo.id, isPollingEnabled]);

  return (
    <Container>
      <div className="row-program-analysis">
        <div className="col-drawer">
          <FilterDrawer />
        </div>
        <div className="main-content">
          {
            displayOverview() ? (
              <>
                <PAOverview />
                <TaskList />
              </>
            ) : switchMainScreen()
          }
        </div>
      </div>
    </Container>
  );
};

export default PAnalysis;
