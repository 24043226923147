import React from 'react';
import {
  Row, Col, Container, Typography,
} from '@airbus/components-react';
import './MpdContentData.scss';
import MpdTaskTable from '../../components/MpdTaskTable/MpdTaskTable';
import MpdGeneralInfoCard from '../../components/MpdGeneralInfoCard/MpdGeneralInfoCard';
import { useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { MODULE_NAME_MAPPING } from '../../models/mpdFilterDrawerModel/constants';

const MpdContentData = function MpdContentData() {
  const {
    ModuleName: selectedMpdModule,
  } = useAppSelector((state: RootState) => state.mpdDrawer);
  const key: ModuleNameType = selectedMpdModule as ModuleNameType;

  return (
    <Container>
      <Row>
        <Col className="align-emtl-general-info-cls">
          <Typography variant="large">
            <b>{MODULE_NAME_MAPPING[key].generalInfoTitle}</b>
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col className="mpd-emtl-general-info-col">
          <MpdGeneralInfoCard />
        </Col>
      </Row>
      <Row>
        <Col className="mpd-col-main-frame">
          <MpdTaskTable />
        </Col>
      </Row>
    </Container>
  );
};

export default MpdContentData;
