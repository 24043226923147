// Slice
export const MPD_GEN_INFO_SLICE = 'mpdGeneralInfo';

// Async thunk actions
export const FETCH_MPD_GEN_INFO = 'fetchMpdGenInfo';
export const CREATE_MPD_GEN_INFO = 'createMpdGenInfo';

// API Path
export const FETCH_API_PATH = 'api/csm/emtlCardInformation';
export const FETCH_API_PATH_MPD_GEN_INFO = 'api/csm/mpdgeneralinfo';
