import React from 'react';
import {
  Button, ComboBox, Spinner, Typography,
} from '@airbus/components-react';
import './FilterCombos.scss';
import { useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { filtersValues } from '../../models/programAnalysis/programAnalysisTypes';
import { TIME_FRAME_YEAR } from '../../models/programAnalysis/constants';
import { allFiltersKeys, timeframes } from './constants';

type FilterComboProps = {
  optsFromReq: Partial<filtersValues>;
  filterId: string;
  filterText: string;
  getNextFilters: (values: string[], filterId: string) => void;
  selectAllValues: (filterId: string) => void;
  startId?: string;
};

const FilterCombobox = (props: FilterComboProps) => {
  const {
    optsFromReq, filterId, getNextFilters, selectAllValues, filterText, startId,
  } = props;
  const filtersObj = useAppSelector((state: RootState) => state.programAnalysis.filters);

  const handleChange = (value: string[]) => {
    getNextFilters(value, filterId);
  };
    /*
    Small helper function to send condition to isLoadingFunc based on architecture
   */
  const getLoadingCondition = () => {
    return allFiltersKeys.indexOf(filterId) !== allFiltersKeys.indexOf(startId as string);
  };

  /*
    Small helper function to decide if we should be displaying a spinner/loading elem or the ComboBox
   */
  const isLoadingFunc = () => {
    if (filterId === TIME_FRAME_YEAR) return false;
    if (filtersObj.fetchInfo.loading && getLoadingCondition()) return true;
    return false;
  };

  let optsCombo;
  if (filterId === TIME_FRAME_YEAR) {
    optsCombo = timeframes;
  } else {
    optsCombo = [];
    if (Array.isArray(optsFromReq[filterId])) {
      optsCombo = optsFromReq[filterId];
    }
  }

  /**
   * Small helper function to decide if we should display "Select" or "Deselect" along with the number of options
   * @param id The ID (ac_type, ac_series, etc.) of the ComboBox
   * @return An HTML element with the right text to display
   */
  const textSelect = (id: string) => {
    let text = `Select All ${optsFromReq[id]?.length}`;
    if (optsFromReq[id] && (filtersObj.selected as filtersValues)[id] && (filtersObj.selected as filtersValues)[id].length === optsFromReq[id].length) {
      text = `Deselect All ${(filtersObj.selected as filtersValues)[id]?.length}`;
    }
    return <small>{text}</small>;
  };

  return (
    <div className="filter-drawer-combo">
      <div className="combobox-label">
        <Typography variant="small" className="displayed-text">
          {filterText}
          <span style={{ color: '#da1884', fontSize: '1.05rem', fontWeight: 'bolder' }}>&nbsp;*</span>
        </Typography>
        {filterId !== TIME_FRAME_YEAR && !isLoadingFunc() && (
          <Button variant="ghost" onClick={() => selectAllValues(filterId)} size="xsmall" id={filterId}>
            {textSelect(filterId)}
          </Button>
        )}
      </div>

      {isLoadingFunc() ? (
        <Spinner />
      ) : (
        <ComboBox
          multiple={filterId !== TIME_FRAME_YEAR}
          options={optsCombo}
          value={(filtersObj.selected as filtersValues)[filterId] || ''}
          onChange={(value) => {
            handleChange(value);
          }}
          aria-label={`${filterId.replace('_', '-')}`}
          placeholder={`${filterText}`}
          limitChips={2}
          disabled={filtersObj.fetchInfo.loading}
        />
      )}
    </div>
  );
};

FilterCombobox.defaultProps = {
  startId: '',
};
export default FilterCombobox;
