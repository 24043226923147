// Slice
export const MPD_DRAWER_SLICE = 'mpdDrawer';

// Async thunk actions
export const FETCH_MPD_TASKS = 'fetchMPDTasks';
export const CREATE_MPD_FILTER = 'createMPDFilter';

// API Path
export const FILTER_API_PATH = 'api/csm/filters';

// Effective Maintenance Task options
export const RADIO_OPTIONS = [
  {
    label: 'EMTL', value: 'EMTL', description: 'EMTL is the effective maintenance task list based on your MSN configuration', disabled: false,
  },
  {
    label: 'Other Sources', value: 'OTR', description: 'Requirement from other sources AD, AOT, ISB, ISI, OIT...', disabled: true,
  },
  {
    label: 'MRBR', value: 'MRBR', description: null, disabled: true,
  },
  {
    label: 'ALS', value: 'ALS', description: null, disabled: true,
  },
];

export const MODULE_NAME_MAPPING = {
  EMTL: {
    generalInfoTitle: 'EMTL General Information',
    tableTitle: 'Effective Maintenance Task List',
  },
  OTR: {
    generalInfoTitle: 'Other Sources General Information',
    tableTitle: 'Other Sources List',
  },
  MRBR: {
    generalInfoTitle: 'MRBR General Information',
    tableTitle: 'MRBR Effective Maintenance Task List',
  },
  ALS: {
    generalInfoTitle: 'ALS Revision General Information',
    tableTitle: 'ALS Effective Maintenance Task List',
  },
  '': {
    generalInfoTitle: '',
    tableTitle: '',
  },
};
