import { initialState } from './mpdRepositorySlice';

export const createRepositoryExportTaskRejectedReducer = (state: repositoryTaskListState) => ({
  ...state,
  RespositoryExportFileDownloadError: true,
});

export const clearAllEmtlExportTaskBannerReducer = (state: repositoryTaskListState) => ({
  ...state,
  RespositoryExportFileDownloadError: false,
});

export const updateEmtlExportTaskReducer = (state: repositoryTaskListState, action: downloadURL) => ({
  ...state,
  RepositoryExportFileDownloadURL: action.payload,
});

export const updateEmtlPageNumberReducer = (state: repositoryTaskListState, action: repositoryTaskListNumberAction) => ({
  ...state,
  RepositoryTaskListCurPageNumber: action.payload,
});

export const updateEmtlRowsPerPageReducer = (state: repositoryTaskListState, action: repositoryTaskListNumberAction) => ({
  ...state,
  RepositoryTaskListRowsPerPage: action.payload,
});

export const addSelectedFileReducer = (state: repositoryTaskListState, action: addSelectedFileAction) => ({
  ...state,
  RepositorySelectedFilesList: [...state.RepositorySelectedFilesList, ...action.payload.fileName],
  RepositorySelectedFileKeys: [...state.RepositorySelectedFileKeys, ...action.payload.fileKey],
});

export const updateSelectedFileReducer = (state: repositoryTaskListState, action: addSelectedFileAction) => ({
  ...state,
  RepositorySelectedFilesList: action.payload.fileName,
  RepositorySelectedFileKeys: action.payload.fileKey,
});

export const deleteRepositoryTaskListDataReducer = (state: repositoryTaskListState, action: { payload: string }) => ({
  ...state,
  RepositoryTaskListDataType: state.RepositoryTaskListDataType.filter((file) => file.filterModuleVersion !== action.payload),
  RepositoryTaskListRowCountType: state.RepositoryTaskListRowCountType - 1,
});

// Repository task list async action
export const createRepositoryTaskListDataFullfilledReducer = (state: repositoryTaskListState, action: repositoryTaskListDataFullfilledAction) => ({
  ...state,
  RepositoryTaskListDataType: action.payload.data,
  RepositoryTaskListRowCountType: Number(action.payload.totalHits),
  RepositoryTaskListLoading: false,
});

export const createRepositoryTaskListDataPendingReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryTaskListLoading: true,
});

export const createMpdEmtlTableExportRejectedReducer = (state: repositoryTaskListState, action: repositoryTaskListDataRejectedAction) => ({
  ...state,
  RepositoryTaskListDataType: [],
  RepositoryTaskListLoading: false,
  error: action.payload || action.error.message,
});

export const createRepositoryExportTaskFullfilledReducer = (state: repositoryTaskListState, action: repositoryExportTaskFullfilledAction) => ({
  ...state,
  RepositoryExportFileDownloadURL: action.payload.data.downloadUrl,
});

// Repository compare file downlaod async action
export const createCompareFileDownloadFullfilledReducer = (state: repositoryTaskListState, action: downloadURL) => ({
  ...state,
  RepositoryCompareFileDownloadURL: action.payload,
});

export const addFilterOptionsFullfilled = (state: repositoryTaskListState, action: addFilterOptionsFullfilled) => ({
  ...state,
  RepositoryFilterOptionsType: action.payload.data.column_value,
});

export const updateRepositoryFilterReducer = (state: repositoryTaskListState, action: updateFilterTypeAtion) => ({
  ...state,
  RepositoryFilterGroup: action.payload,
  RepositoryTaskListCurPageNumber: 1,
});

export const updateRepositoryTabReducer = (state: repositoryTaskListState, action: downloadURL) => ({
  ...state,
  RepositoryEMTLTabType: action.payload,
  RepositoryFilterGroup: [],
  RepositoryTaskListCurPageNumber: 1,
  RepositoryTaskListRowCountType: 0,
});

export const setButtonDisabledReducer = (state: repositoryTaskListState, action: downloadURL) => ({
  ...state,
  RepositoryButtonDisabled: action.payload,
});

export const createRepositoryDeltaTableViewFullfilledReducer = (state: repositoryTaskListState, action: repoDeltaViewDataFullfilled) => ({
  ...state,
  RepositoryDeltaViewData: {
    filter_data: action.payload.data.filter_data,
    headers: (JSON.stringify(action.payload.data.headers) !== JSON.stringify(state.RepositoryDeltaViewData.headers)) ? action.payload.data.headers : state.RepositoryDeltaViewData.headers,
    records: action.payload.data.records,
    totalHits: action.payload.totalHits,
  },
  RepositoryDeltaViewDataLoading: false,
});

export const createRepositoryDeltaTableViewFilterFullfilledReducer = (state: repositoryTaskListState, action: addFilterOptionsFullfilled) => ({
  ...state,
  RepositoryDeltaViewFilterData: {
    column_name: action.payload.data.column_name,
    column_value: action.payload.data.column_value,
  },
  RepositoryDeltaViewFilterDataSanitized: {
    column_name: action.payload.data.column_name,
    column_value: action.payload.data.column_value.map((val: string) => (val.replace('\u00a0', ' ')).replace(/[\n\r]/g, ' ')),
  },
});

export const clearRepositoryDeltaTableViewFilterReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryDeltaViewFilterData: {
    column_name: '',
    column_value: [],
  },
  RepositoryDeltaViewFilterDataSanitized: {
    column_name: '',
    column_value: [],
  },
});

export const createRepositoryDeltaTableViewRejectedReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryDeltaViewDataLoading: false,
  RepositoryDeltaViewDataError: true,
});

export const clearAllRepoDeltaTableViewReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryDeltaViewData: {
    filter_data: [],
    headers: [],
    records: [],
    totalHits: 0,
  },
  RepositoryDeltaViewDataLoading: true,
  RepositoryDeltaViewDataError: false,
});

export const clearAllRepoDeltaTableViewDataReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryDeltaViewData: {
    ...state.RepositoryDeltaViewData,
    records: [],
  },
  RepositoryDeltaViewDataLoading: true,
  RepositoryDeltaViewDataError: false,
});

export const setFilterPanelOpenReducer = (state: repositoryTaskListState, action: setFilterPanelOpenAction) => ({
  ...state,
  RepositoryFilterPanalOpen: action.payload,
});

export const updateGeneralInfoCardDataFulfilled = (state: repositoryTaskListState, action: generalInfoCardDataSuccessAction) => {
  const estFromData = [
    [
      {
        title: 'MPD Revision',
        value: `${action.payload.data.reference_file_general_info.mpdRevision}`,
      },
    ],
    [
      {
        title: 'MPD Revision',
        value: `${action.payload.data.input_file_general_info.mpdRevision}`,
      },
    ],
  ];

  /* istanbul ignore else */
  if ('mpdIssueNumber' in action.payload.data.reference_file_general_info) {
    estFromData[0].push({
      title: 'Issue Number',
      value: `${action.payload.data.reference_file_general_info.mpdIssueNumber}`,
    });
  }

  /* istanbul ignore else */
  if ('mpdIssueNumber' in action.payload.data.input_file_general_info) {
    estFromData[1].push({
      title: 'Issue Number',
      value: `${action.payload.data.input_file_general_info.mpdIssueNumber}`,
    });
  }

  estFromData[0].push({
    title: 'Revision Date',
    value: action.payload.data.reference_file_general_info.revisionDate,
  });

  estFromData[1].push({
    title: 'Revision Date',
    value: action.payload.data.input_file_general_info.revisionDate,
  });

  /* istanbul ignore else */
  if (action.payload.data.reference_file_general_info.mpdIssueDate) {
    estFromData[0].push({
      title: 'Issue Date',
      value: action.payload.data.reference_file_general_info.mpdIssueDate,
    });
  }

  /* istanbul ignore else */
  if (action.payload.data.input_file_general_info.mpdIssueDate) {
    estFromData[1].push({
      title: 'Issue Date',
      value: action.payload.data.input_file_general_info.mpdIssueDate,
    });
  }

  return {
    ...state,
    RepositoryDeltaGeneralInfoData: action.payload.data,
    RepositoryDeltaViewCardIssueData: [
      [
        {
          title: 'Issue',
          value: action.payload.data.reference_file_general_info.issueNumber,
        },
        {
          title: 'Issue Date',
          value: action.payload.data.reference_file_general_info.issueDate,
        },
      ],
      [
        {
          title: 'Issue',
          value: action.payload.data.input_file_general_info.issueNumber,
        },
        {
          title: 'Issue Date',
          value: action.payload.data.input_file_general_info.issueDate,
        },
      ],
    ],
    RepositoryDeltaViewCardEstablishedData: estFromData,
  };
};

export const updateGeneralInfoCardDataRejected = (state: repositoryTaskListState, action: repositoryTaskListDataRejectedAction) => ({
  ...state,
  error: action.payload || action.error?.message,
});

export const clearGeneralInfoCardDataReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryDeltaGeneralInfoData: initialState.RepositoryDeltaGeneralInfoData,
  RepositoryDeltaViewCardIssueData: initialState.RepositoryDeltaViewCardIssueData,
  RepositoryDeltaViewCardEstablishedData: initialState.RepositoryDeltaViewCardEstablishedData,
});

export const checkPreviousRevisionExistFulfilled = (state: repositoryTaskListState, action: prevRevisionFullfilled) => ({
  ...state,
  RepositoryPreviousRevision: {
    ...state.RepositoryPreviousRevision,
    isLatest: action.payload.is_latest,
    isRevisionExist: action.payload.is_revision_exist,
  },
});

export const setPrevRevExportInProgressReducer = (state: repositoryTaskListState, action: { payload: boolean }) => ({
  ...state,
  RepositoryPreviousRevision: {
    ...state.RepositoryPreviousRevision,
    prevRevExportInProgress: action.payload,
  },
});

export const resetPreviousRevisionBannerReducer = (state: repositoryTaskListState) => ({
  ...state,
  RepositoryPreviousRevision: {
    ...state.RepositoryPreviousRevision,
    isLatest: false,
    isRevisionExist: true,
  },
});
