import tasksSlice from '../models/taskReportModel/taskReportSlice';
import mpdDrawerSlice from '../models/mpdFilterDrawerModel/mpdDrawerSlice';
import emtlTableSlice from '../models/mpdEmtlTableModel/mpdEmtlTableSlice';
import MpdGeneralInfoSlice from '../models/MpdGeneralInfoModel/MpdGeneralInfoSlice';
import bannerSlice from '../models/bannerModel/bannerSlice';
import repositoryTaskListSlice from '../models/mpdRepositoryModel/mpdRepositorySlice';
import programAnalysisSlice from '../models/programAnalysis/programAnalysisSlice';
import unscheduledEventSlice from '../models/unscheduledEventsModel/unscheduledEventSlice';
import apiPollingSlice from '../models/APIPollingModel/APIPollingSlice';
import PARepositorySlice from '../models/programAnalysisRepository/PARepositorySlice';
import featureToggleSlice from '../models/FeatureToggleModel/FeatureToggleSlice';
import toastSlice from '../models/ToastModel/toastSlice';
import deltaViewSoctSlice from '../models/mpdRepositoryModel/SOC/deltaViewSocSlice';
import PABuildListSlice from '../models/PABuildList/PABuildListSlice';
import unscheduledEventPrecompSlice from '../models/unscheduledEventsPrecompModel/unscheduledEventsPrecompSlice';
import BellNotificationSlice from '../models/BellNotificationModel/BellNotificationSlice';
import userPermissionSlice from '../models/userPermissionsModel/userPermissionSlice';

export const rootReducer = {
  [tasksSlice.name]: tasksSlice.reducer,
  [programAnalysisSlice.name]: programAnalysisSlice.reducer,
  [mpdDrawerSlice.name]: mpdDrawerSlice.reducer,
  [emtlTableSlice.name]: emtlTableSlice.reducer,
  [MpdGeneralInfoSlice.name]: MpdGeneralInfoSlice.reducer,
  [bannerSlice.name]: bannerSlice.reducer,
  [repositoryTaskListSlice.name]: repositoryTaskListSlice.reducer,
  [unscheduledEventSlice.name]: unscheduledEventSlice.reducer,
  [unscheduledEventPrecompSlice.name]: unscheduledEventPrecompSlice.reducer,
  [apiPollingSlice.name]: apiPollingSlice.reducer,
  [PARepositorySlice.name]: PARepositorySlice.reducer,
  [featureToggleSlice.name]: featureToggleSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [deltaViewSoctSlice.name]: deltaViewSoctSlice.reducer,
  [PABuildListSlice.name]: PABuildListSlice.reducer,
  [BellNotificationSlice.name]: BellNotificationSlice.reducer,
  [userPermissionSlice.name]: userPermissionSlice.reducer,
};
